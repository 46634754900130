import * as React from 'react';
import { useEffect } from 'react'

import imageDbIcon from '../../images/ImageDbIco.png';
import indboIcon from '../../images/Indbo5.png';
import indboSelectFieldsHtmlReport from '../../images/Indbo/Indbo530.Select.Fields.Html.Report.png';
import indboHtmlReport from '../../images/Indbo/Indbo530.Html.Report.png';
import inventoryDbIcon from '../../images/InventoryDBIco.png';
import petrisoftLogo from '../../images/petrisoftLogo50x67.gif';
import tracksportIcon from '../../images/TrackSport.jpg';
import newImageDBWith4Fields from '../../images/ImageDB/ImageDBMusikDialog.png';
import { Link } from 'react-router-dom';
import './News.css';
import { appInsights } from 'src/AppInside';


export default function News() {

  useEffect(() => {
    appInsights.trackPageView({
      name: 'Petrisoft Prices page', uri: "https://www.petrisoft.dk/news",
      isLoggedIn: true, properties: { "action": "componentDidMount" }
    });
  }, [])

  return (
    <div>

      <header>
        <h2>Nyheder fra PetriSoft</h2>
      </header>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col">Dato</th>
            <th scope="col">Ikon</th>
            <th scope="col">Opdatering</th>
            <th scope="col">Beskrivelse</th>
          </tr>
        </thead>

        <tbody>

          <tr>
            <th scope="row">
              11.02.2025
            </th>

            <td>
            </td>

            <td>PetriSoft webside
            </td>

            <td>
              Hjemmeside opdatering (PetriSoft)
              <ul>
                <li>Tilføjet betaling via kreditkort eller Paypal - gælder Indbo Std, ImageDB og Tracksport</li>
              </ul>
            </td>
          </tr>

          <tr>
            <th scope="row">
              07.01.2024
            </th>

            <td>
              <Link to="/indbo">
                <img className="img-app-icon" src={indboIcon} alt="Indbo" />
              </Link>
            </td>

            <Link to="/indbo">
              Indbo 5.3.0 (.Net Core).
            </Link>

            <td>
              Indbo html-report funktionen er kraftig opdateret, så man kan filtere, vælge felter samt vise indbo i en html tabel som kan
              kopieres printes osv.

              <h6>Nyt i denne version:</h6>

              <ul>
                <li>Nyhed/Forbedret funktion : "Vis i HTML tabel... dialog til at udvælge felter der skal med i rapport:"</li>
                <p />
                <img src={indboSelectFieldsHtmlReport} alt="Indbo - select fields" />
              </ul>
              <ul>
                <li>Nyhed: Rapport med fremsøgte indbo enheder: "Søg/Vis i HTML tabel... I dette eksempel har man søgt efter alle poster med 'B&0':"</li>
                <img src={indboHtmlReport} alt="Indbo - select fields" />
              </ul>

              <ul>
                <li>Nyhed: "Søg/Vis i HTML tabel... klik på billede1/billede2 og vis det i oprindelig størrelse"</li>
                <li>Rettelse: poster nummeres korrekt og slettes en post genbruges nummeret</li>
              </ul>

              <p>
                En demo version af nyeste Indbo 5.3.0 kan hentes her:&nbsp;
                <Link to="/download">Download</Link>
              </p>

              Hent Indbo.exe, og placer den på dit skrivebord (eller opret en genvej til programmet)
              og kør programmet ved at dobbelt klikke på Indbo.exe ikonet.

            </td>
          </tr>


          <tr>
            <th scope="row">
              18.01.2023
            </th>

            <td>
              <Link to="/indbo">
                <img className="img-app-icon" src={indboIcon} alt="Indbo" />
              </Link>
            </td>

            <Link to="/indbo">
              Indbo 5.1.0 (.Net Core).
            </Link>

            <td>
              Indbo er opdateret med ny html-report funktion, så man kan vise alt indbo i en html tabel som kan
              kopieres printes osv.

              <p>
                En demo version af nyeste Indbo 5.1.0 kan hentes her:&nbsp;
                <Link to="/download">Download</Link>
              </p>

              Hent Indbo.exe, og placer den på dit skrivebord (eller opret en genvej til programmet)
              og kør programmet ved at dobbelt klikke på Indbo.exe ikonet.

              <h6>Nyt i denne version:</h6>

              <ul>
                <li>Ny funktion tilføjet: "Vis i HTML tabel..."</li>
              </ul>

            </td>

          </tr>

          <tr>
            <th scope="row">
              09.11.2022
            </th>
            <td>
              <Link to="/prices">
                <img className="img-app-icon" src={petrisoftLogo} alt="Petrisoft" />
              </Link>
            </td>
            <Link to="/Prices">
              PetriSoft opdateret
            </Link>

            <td>
              Petrisoft web site opdateret
              <h6>Nyt i denne version:</h6>
              <ul>
                <li>Priser siden opdateret med tabel</li>
                <li>Nyhed: køb mulighed for at kunne opdatere din licens så snart der frigives nye versioner</li>
                <li>"Køb app" formaular opdateret</li>
              </ul>
            </td>

          </tr>

          <tr>
            <th scope="row">
              28.12.2021
            </th>

            <td>
              <Link to="/indbo">
                <img className="img-app-icon" src={indboIcon} alt="Indbo" />
              </Link>
            </td>

            <Link to="/indbo">
              Indbo 5.0.3 (.Net Core).
            </Link>

            <td>
              Indbo programmet til registrering af indbo og inventar er nu nyudviklet i C#,
              Wpf og .Net Core. Det er muligt at importere data fra det "gamle"
              Indbo 4.6.0 via funktionen "Indlæs db fra Csv.

              <p>
                En demo version af nyeste Indbo 5.0.3 kan hentes her:&nbsp;
                <Link to="/download">Download</Link>
              </p>

              Hent Indbo.exe, og placer den på dit skrivebord (eller opret en genvej til programmet)
              og kør programmet ved at dobbelt klikke på Indbo.exe ikonet.

              <h6>Nyt i denne version:</h6>

              <ul>
                <li>New calender/date picker added</li>
                <li>New release note link in "About Indbo"</li>
                <li>Fixed entering decimals in price fields in item dialog</li>
                <li>Fixed resize of item dialog</li><li>Fized max size problem</li>
                <li>Fixed edit of locations file if user changes to another db.</li>
                <li>Fixed last saved db is not overwritten if user import a new csv file (dialog)</li>
                <li>Fixed "Save as" - now also saves groups and locations in destination folder</li>
              </ul>

            </td>

          </tr>
          <tr>
            <th scope="row">
              26.09.2021
            </th>
            <td>
              <Link to="/tracksport">
                <img className="img-app-icon" src={tracksportIcon} alt="TrackSport" />
              </Link>
            </td>
            <td>
              <Link to="/tracksport">
                TrackSport 3.0.2
              </Link>
            </td>
            <td>
              TrackSport programmet til registrering af træning og konkurrencer i løb, tri, cykelløb, mtb, mm
              <br />
              Hent TrackSport.exe, læg filen på dit skrivebord og kør programmet ved at dobbelt klikke på TrackSport.exe ikonet.

              I denne version er registrering af licens rettet, inddatering af distance og hastighed kan angives med decimaler -
              f.eks. varighed: 1 time 5 min og 5 sekunder indtastes som: 65,05 (min,sek).
            </td>
          </tr>

          <tr>
            <th scope="row">
              21.05.2021
            </th>
            <td>
              <Link to="/tracksport">
                <img className="img-app-icon" src={tracksportIcon} alt="TrackSport" />
              </Link>
            </td>
            <td>
              <Link to="/tracksport">
                TrackSport 3.0.1
              </Link>
            </td>
            <td>
              TrackSport programmet til registrering af træning og konkurrencer i løb, cykelløb, mtb, mm
              er nu nyudviklet i C#, Wpf og .Net Core. <br />
              Hent TrackSport.zip, unzip filen på dit skrivebord og kør programmet ved at dobbelt klikke på TrackSport.exe ikonet.
              <br />
              <a href="https://www.screencast.com/t/1tT5TjWOz" >Se video om hvordan du henter, installerer og
                starter med at bruge TrackSport</a>
              <br />
              I denne version er et problem med installation på nogle Windows 10 versioner rettet
            </td>
          </tr>

          <tr>
            <th scope="row">
              10.04.2021
            </th>
            <td>
              <Link to="/indbo">
                <img className="img-app-icon" src={indboIcon} alt="Indbo" />
              </Link>
            </td>
            <td>
              <Link to="/indbo">
                Indbo 5.0.0 (ny .Net Core version).
              </Link>
            </td>
            <td>
              Indbo programmet til registrering af indbo og inventar er nu nyudviklet i C#, Wpf og .Net Core. <br />
              Det er muligt at importere data fra det "gamle" Indbo 4.6.0 via funktionen "Indlæs db fra Csv.
              <p>
                En demo version af nyeste Indbo 5.0.0 kan hentes her:&nbsp;
                <Link to="/download">Download</Link>
              </p>

              Hent Indbo.exe, og placer den på dit skrivebord (eller opret en genvej til programmet)
              og kør programmet ved at dobbelt klikke på Indbo.exe ikonet.

            </td>
          </tr>

          <tr>
            <th scope="row">
              08.04.2020
            </th>
            <td>
              <Link to="/tracksport">
                <img className="img-app-icon" src={tracksportIcon} alt="TrackSport" />
              </Link>
            </td>
            <td>
              <Link to="/tracksport">
                TrackSport 3.0.0
              </Link>
            </td>
            <td>
              TrackSport programmet til registrering af træning og konkurrencer i løb, cykelløb, mtb, mm
              er nu nyudviklet i C#, Wpf og .Net Core. <br />
              Hent TrackSport.zip, unzip filen på dit skrivebord og kør programmet ved at dobbelt klikke på TrackSport.exe ikonet.
              <br />
              <a href="https://www.screencast.com/t/1tT5TjWOz" >Se video om hvordan du henter, installerer og
                starter med at bruge TrackSport</a>
            </td>
          </tr>

          <tr>
            <th scope="row">
              07.07.2019
            </th>
            <td>
              <img className="img-app-icon" src={petrisoftLogo} alt="Www.PetriSoft.dk" />
            </td>
            <td>
              PetriSoft web side
            </td>
            <td>
              PetriSoft's website: <a href="https://www.petrisoft.dk">www.petrisoft.dk</a> er opdateret med nyt design og indhold. Den er lavet i ReactJS, Typescript samt Bootstrap.
            </td>
          </tr>

          <tr>
            <th scope="row">
              27.08.2018
            </th>
            <td>
              <Link to="/imagedb">
                <img src={imageDbIcon} className="img-app-icon" alt="ImageDB" />
              </Link>
            </td>
            <td>
              <Link to="/imagedb">
                ImageDB 4.4.1
              </Link>
            </td>
            <td>
              Nu med support for netværksdrev - D.v.s. du kan gemme/hente data på ekstern drev på nettet - f.eks. drev Z: eller lignende...
              <br />
              Det kan derfor nu bedre anvendes i en netværks løsning med f.eks automatisk backup på server eller lignende...
            </td>
          </tr>
          <tr>
            <th scope="row">
              27.02.2018
            </th>
            <td>
              <Link to="/inventorydb">
                <img src={inventoryDbIcon} className="img-app-icon" alt="InventoryDB" />
              </Link>
            </td>
            <td>
              <Link to="/inventorydb">
                InventoryDB 2.2.0
              </Link>
            </td>
            <td>
              Nu med support for netværksdrev - D.v.s. du kan gemme/hente data på drev på nettet - f.eks. drev Z: eller lignende...<br />
              Det kan derfor nu bedre anvendes i en netværks løsning med flere klienter der tilgår samme database og med f.eks automatisk backup på netværkserver, NAS eller lignende...
            </td>
          </tr>

          <tr>
            <th scope="row">
              10.04.2017
            </th>
            <td>
              <Link to="/tracksport">
                <img className="img-app-icon" src={tracksportIcon} alt="TrackSport" />
              </Link>
            </td>
            <td>
              <Link to="/tracksport">
                TrackSport 2.3.0
              </Link>
            </td>
            <td>
              TrackSport - med nyttige nye funktioner:<br /><br />.
              Mulighed for at lave forskellige registre - et til løb og en anden til cykling... så man ikke behøver at blande aktiviteter i samme database
              <br />. Nyt dokument/weblink felt - så der nu er mulighed for op til 3 links til resultater, billeder osv.
              <br />. 'Åben'' fil funktion i toolbar: Du kan nu åbne forskellige resultat filer: f.eks. en til løb og en anden til cykling...
              <br />. "Gem som" fil funktion i toolbar: Du kan nu gemme forskellige resultat filer: f.eks. en til løb og en anden til cykling...
              <br />. Gem og husk sidste fil du har åbnet og brug denne næste gang du starter
              <p />
            </td>
          </tr>

          <tr>
            <td>17.10.2016</td>
            <td>
              <Link to="/imagedb">
                <img className="img-app-icon" src={imageDbIcon} alt="ImageDB" />
              </Link>
            </td>
            <td>
              <Link to="/imagedb">
                ImageDB 4.4.0
              </Link>
            </td>
            <td>
              ImageDB er udvidet med nyt database design der indeholder flere nye felter så man har flere muligheder for at beskrive sin film og musik samling mm.
              <br />. Musik: der er tilføjet 4 nye felter, kan konfigureres i programmet:
              Serie nummer, Bemærkninger, Kvalitet, Bedømmelse(1-5)
              <br />
              <img width="600" src={newImageDBWith4Fields} alt="" />
              <br />. Film/bøger: der er tilføjet 4 nye felter, kan konfigureres i programmet:
              Serie nummer, Bemærkninger, Kvalitet, Web Link 2
              <br />. Diverse andre forbedringer
            </td>
          </tr>

          <tr>
            <th scope="row">
              22.12.2016
            </th>
            <td>
              <Link to="/imagedb">
                <img className="img-app-icon" src={imageDbIcon} alt="ImageDB" />
              </Link>
            </td>
            <td>
              <Link to="/imagedb">
                ImageDB 4.4.0
              </Link>
            </td>

          </tr>
        </tbody>
      </table>
    </div >
  )
}

//export default News;
